* {
  font-family: 'PT Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  position: relative;
  background: #f5f8fb;
  min-height: 100vh;
}

.container {
  max-width: 1395px;
  margin: 0 auto 0 0;
  width: 100%;
  padding: 0 20px;
}

.content {
  margin-left: 290px;
  padding-left: 30px;
  padding-top: 110px;
}

.slick-slider {
  padding-right: 20px;
  padding-left: 15px;
}

/* .slick-active {
  margin-left: -15px;
} */

@media (min-width: 1921px) {
  .container {
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .content {
    margin-left: 0;
    padding-left: 0;
    padding-top: 100px;
  }

  .slick-slider {
    padding: 0;
    padding-right: 5px;
  }
}

@media (max-width: 425px) {
  .container {
    padding: 0 10px;
  }

  /* .slick-slider {
    padding: 0;
  } */
}
